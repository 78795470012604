
import {useEffect} from "react";
const Layout = ({children})=>{
    useEffect(()=>{
        if(document.querySelector('link[href="/auth.css"]')){
            return
        }
        const link = document.createElement("link");
        link.rel="stylesheet"
        link.href = "/auth.css";
        document.head.appendChild(link);
        console.log('append')
        return ()=>{
            let link = document.querySelector('link[href="/auth.css"]')
            if(link){
                link.remove()
            }
        }
    },[])
    return (
        <div id="auth">

            <div className="row h-100">
                <div className="col-lg-5 col-12">
                    {children}
                </div>
                <div className="col-lg-7 d-none d-lg-block">
                    <div id="auth-right">

                    </div>
                </div>
            </div>

        </div>
    )
}
export default Layout
