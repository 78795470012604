import {useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import ApiContext from "../ApiContext";

const Detail = ({title,info = [], model,expand="",files=[],children,tables=[],status=[],text=[]})=>{
    const params = useParams()
    const pb = useContext(ApiContext)
    const [record,setRecord]=useState(null)
    const [form,setForm]=useState({

    })
    const [stat,setStat]=useState(null)
    useEffect(()=>{
        load()
    },[])
    const load = ()=>{
        pb.collection(model).getOne(params.id,{
            expand:expand
        }).then(res=>{
            console.log("res",res)
            setRecord(res)
            if(model==="socials"){
                pb.collection("user_stats").getOne(res.user).then(statRecord=>{
                    setStat(statRecord)
                })
            }
        })
    }

    const updateForm=(key,value)=>{
        let obj = {}
        obj[key]=value
        setForm({
            ...form,
            ...obj
        })
    }
    const update = ()=>{
        rejectMail().then(()=>{
            pb.collection(model).update(params.id,form).then(()=>{
                alert("변경완료")
                load()
            })
        })
    }

    const rejectMail = ()=>{
        if(form.status==="신청반려" || form.status==="취소"){
            return pb.collection("users").requestVerification(record.expand.user.email)
        }else{
            return Promise.resolve()
        }
    }

    return (
        <div className="card">
            <div className="card-content">
                <div className="card-body">
                    <h4 className="card-title">{title}</h4>
                    {
                        record?
                            <>
                                <form className="form" method="post">
                                    <div className="form-body">
                                        <div className={"row"}>
                                            <div className={"col"}>
                                                {
                                                    info.map(item=>{
                                                        return (
                                                            <div className="form-group">
                                                                <label htmlFor="feedback1" className="sr-only">{item.label}</label>
                                                                <input type="text" className="form-control" defaultValue={item.get(record)} value={item.key?undefined:item.get(record)} disabled={!item.key} onChange={e=>item.key?updateForm(item.key,e.target.value):null} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                                {
                                                    status.length?
                                                        <>
                                                            <h5>상태처리</h5>
                                                            <div>
                                                                {
                                                                    status.map(stat=>{
                                                                        return (
                                                                            <>
                                                                                <span>{stat}</span>
                                                                                <input style={{marginRight:20,marginLeft:5}} type="radio" name={"status"} value={stat} onClick={()=>setForm({...form,status:stat})}/>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </>
                                                        :null
                                                }
                                                {
                                                    stat?
                                                        <>
                                                            <div className="form-group">
                                                                <label htmlFor="feedback1" className="sr-only">적립금</label>
                                                                <input type="text" className="form-control" value={Number(stat.deposit).toLocaleString()} disabled  />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="feedback1" className="sr-only">기환급액</label>
                                                                <input type="text" className="form-control" value={Number(stat.withdraw).toLocaleString()} disabled  />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="feedback1" className="sr-only">가능환급액(다른 신청중인 건 제외)</label>
                                                                <input type="text" className="form-control" value={(Number(stat.deposit)-Number(stat.available)+(record.status==="신청완료"?Number(record.point):0)).toLocaleString()} disabled  />
                                                            </div>
                                                        </>
                                                        :null
                                                }
                                            </div>
                                            {
                                                text.length || files.length?
                                                    <div className={"col"}>
                                                        {
                                                            text.map(area=>{
                                                                return (
                                                                    <div className="form-group">
                                                                        <label htmlFor="feedback1" className="sr-only">{area.label}</label>
                                                                        <textarea className="form-control" name="" id="" cols="30" rows="10" onChange={e=>updateForm(area.key,e.target.value)}>
                                                            {area.get(record)}
                                                        </textarea>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            files.map(item=>{
                                                                return (
                                                                    <div className="form-group">
                                                                        <label htmlFor="feedback1" className="sr-only">{item.label}</label> <br/>
                                                                        <a className={"btn btn-primary"} target={"_blank"} href={pb.getFileUrl(record,item.get(record))} >확인</a>
                                                                        <span>{item.get(record)}</span>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    :null
                                            }
                                        </div>
                                    </div>
                                    {
                                        tables.map(table=>{
                                            return (
                                                <>
                                                    <div key={table.name}>
                                                        <h5>{table.name}</h5>
                                                        <table className="table table-striped" id="table1">
                                                            <thead>
                                                            <tr>
                                                                {
                                                                    table.columns.map(column=>{
                                                                        return (
                                                                            <td key={column}>{column}</td>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                table.expand(record).map(item=>{
                                                                    return (
                                                                        <tr style={{cursor:"pointer"}} key={item.id} onClick={()=>table.navigate(item.id)}>
                                                                            {
                                                                                table.handler(item).map((v,k)=>{
                                                                                    if(Array.isArray(v)){
                                                                                        return (
                                                                                            <td key={table.columns[k]}>
                                                                                                <span className={"badge "+v[1]}>{v[0]}</span>
                                                                                            </td>
                                                                                        )
                                                                                    }else{
                                                                                        return (
                                                                                            <td key={table.columns[k]}>{v}</td>
                                                                                        )
                                                                                    }
                                                                                })
                                                                            }
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                    <hr/>
                                    <div className="form-actions d-flex justify-content-end">
                                        <button type="button" className="btn btn-primary me-1" onClick={update}>저장</button>
                                        <button type="button" className="btn btn-light-primary" onClick={()=>window.history.back()}>뒤로가기</button>
                                    </div>
                                </form>
                            </>:null
                    }
                    {children}
                </div>
            </div>
        </div>
    )
}
export default Detail
