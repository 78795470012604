import {createBrowserRouter, RouterProvider,redirect} from "react-router-dom";
import Login from "./pages/Login";
import Main from "./layouts/Main";
import Dashboard from "./pages/Dashboard";
import ApiContext from "./ApiContext";
import {useContext} from "react";
import UserList from "./pages/UserList";
import UserDetail from "./pages/UserDetail";
import SocialList from "./pages/SocialList";
import SocialDetail from "./pages/SocialDetail";
import PaperList from "./pages/PaperList";
import NoticeList from "./pages/NoticeList";
import PaperDetail from "./pages/PaperDetail";
import NoticeDetail from "./pages/NoticeDetail";
import Mail from "./pages/Mail";



const makeRotues = (pb)=>{
    const routes = [
        {
            path:"/",
            element:<Main />,
            children:[
                {
                    path:"/",
                    element:<Dashboard />
                },
                {
                    path:"/user",
                    element:<UserList />
                },
                {
                    path:"/user/:id",
                    element:<UserDetail />
                },
                {
                    path:"/social",
                    element:<SocialList />
                },
                {
                    path:"/social/:id",
                    element:<SocialDetail />
                },
                {
                    path:"/paper",
                    element:<PaperList />
                },
                {
                    path:"/paper/:id",
                    element:<PaperDetail />
                },
                {
                    path:"/notice",
                    element:<NoticeList />
                },
                {
                    path:"/notice/:id?",
                    element:<NoticeDetail />
                },
                {
                    path:"/mail",
                    element:<Mail />
                }
            ],
            loader(){
                if(pb.authStore.isValid){
                    return null
                }else{
                    return redirect("/login")
                }

            },
        },
        {
            path:"login",
            element:<Login />
        }
    ]
    return createBrowserRouter(routes)
}

const Provider = ()=>{
    const pb = useContext(ApiContext)
    return (
        <RouterProvider router={makeRotues(pb)} />
    )

}
export default Provider
