import List from "../parts/List"

export const STATUS = {
    "신청완료":"bg-primary",
    "입금완료":"bg-success",
    "취소":"bg-danger",
    "담당자확인":"bg-secondary",
}
const Page = ()=>{
    return (
        <List model={"socials"} columns={["회사","부서","담당자","신청적립금","상태","날짜"]} handler={item=>{
            return [
                item.expand.user.company,
                item.dept,
                item.charge,
                Number(item.point).toLocaleString(),
                [item.status,STATUS[item.status]],
                new Date(item.created).toLocaleDateString()
            ]
        }
        }
              keywordMatch={["dept","charge"]}
              expand={"user"}
              statuses={Object.keys(STATUS)}
        >

        </List>
    )
}
export default Page
