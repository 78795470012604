import {useContext, useEffect, useState} from "react";
import ApiContext from "../ApiContext";
import {useNavigate} from "react-router-dom";

const List = ({title,columns,handler,model,expand="",keywordMatch = [],statuses=[],children})=>{
    const navigate = useNavigate()
    const pb = useContext(ApiContext)
    const [keyword,setKeyword]=useState("")
    const [checked,setChecked]=useState([])
    const [info,setInfo]=useState({
        items:[],
        page:1,
        perPage:1,
        totalPages:1,
        totalItems:0
    })
    useEffect(()=>{
       load()
    },[checked])
    const load = (page = 1)=>{
        let keywordFilter = keywordMatch.map(column=>{
            return `${column}~"%${keyword}%"`
        }).join("||")
        let checkedFilter = checked.map(check=>{
            return `status="${check}"`
        }).join("||")
        let filterItems = []
        if(keywordFilter)filterItems.push(keywordFilter)
        if(checkedFilter)filterItems.push(checkedFilter)

        pb.collection(model).getList(page,20,{
            sort:"-created",
            filter:filterItems.map(string=>`(${string})`).join("&&"),
            expand:expand
        }).then(res=>{
            setInfo(res)
        })
    }
    const prev = ()=>{
        if(info.page-1>0){
            load(info.page-1)
        }
    }
    const next = ()=>{
        if(info.page<info.totalPages){
            load(info.page+1)
        }
    }
    return (
        <>
            <div className="page-heading">
                <h3>{title}</h3>
            </div>
            <div className="page-content">
                <section className="section">
                    <div className="card">
                        <div className="card-header">
                            검색
                        </div>
                        <div className="card-body">
                            <div className={"row"}>
                                <div className={"col-4"}>
                                    <p>총 {info.totalItems.toLocaleString()}건 중 {info.items.length.toLocaleString()}건</p>
                                </div>
                                <div className={"col-4"}>
                                    {
                                        statuses.map(status=>{
                                            const toggle = ()=>{
                                                let exist = checked.indexOf(status)!==-1
                                                if(exist){
                                                    setChecked(
                                                        checked.filter(i=>i!==status)
                                                    )
                                                }else{
                                                    setChecked(
                                                        [
                                                            ...checked,
                                                            status
                                                        ]
                                                    )
                                                }
                                            }
                                            return (
                                                <>
                                                    <span style={{paddingLeft:20,paddingRight:10}}>{status}</span>
                                                    <input type="checkbox" value={status} onClick={toggle} />
                                                </>
                                            )
                                        })
                                    }
                                </div>
                                <div className={"col-4"}>
                                    <input placeholder={"검색"} className={"form-control"} type="text" onKeyUp={e=>e.key==="Enter"?load():null} onChange={e=>setKeyword(e.target.value)} onBlur={()=>load()}/>
                                </div>
                            </div>
                            <table className="table table-striped" id="table1">
                                <thead>
                                <tr>
                                    {
                                        columns.map((column,k)=>{
                                            return (
                                                <td style={k===3?{textAlign:"right"}:null} key={column}>{column}</td>
                                            )
                                        })
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    info.items.map(item=>{
                                        let list= handler(item)
                                        return (
                                            <tr style={{cursor:"pointer"}} key={item.id} onClick={()=>navigate(item.id)}>
                                                {
                                                    list.map((v,k)=>{
                                                        if(Array.isArray(v)){
                                                            return (
                                                                <td key={columns[k]}>
                                                                    <span className={"badge "+v[1]}>{v[0]}</span>
                                                                </td>
                                                            )
                                                        }else{
                                                            return (
                                                                <td style={k===3?{textAlign:"right"}:null} key={columns[k]}>{v}</td>
                                                            )
                                                        }
                                                    })
                                                }
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                            <ul className="pagination pagination-primary">
                                <li className="pager page-item">
                                    <a href="#" data-page="1" className="page-link" onClick={prev}>‹</a>
                                </li>
                                {
                                    new Array(5).fill(0).map((v,k)=>{
                                        let p = info.page-2+k
                                        if(p>0 && p<=info.totalPages){
                                            return (
                                                <li className={p===info.page?"active page-item":"page-item"}>
                                                    <a href="#" className="page-link" onClick={()=>load(p)}>{p}</a>
                                                </li>
                                            )
                                        }
                                        return null
                                    })
                                }
                                <li className="pager page-item">
                                    <a href="#" data-page="2" className="page-link" onClick={next}>›</a>
                                </li>
                            </ul>
                            {
                                children
                            }
                        </div>
                    </div>

                </section>
            </div>
        </>
    )
}
export default List
