import List from "../parts/List"
import {useState} from "react";
export const STATUS = {
    "신청완료":"bg-primary",
    "적립완료":"bg-success",
    "신청반려":"bg-danger",
    "담당자확인":"bg-secondary",
}
const Page = ()=>{
    return (
        <List model={"papers"} columns={["회사","부서","구매자","구매금액","상태","날짜"]} handler={item=>{
            return [
                item.expand.user.company,
                item.dept,
                item.buyer,
                Number(item.amount).toLocaleString(),
                [item.status,STATUS[item.status]],
                new Date(item.created).toLocaleDateString()
            ]
        }
        }
              statuses={["신청완료","적립완료","신청반려"]}
              keywordMatch={["buyer","dept"]}
              expand={"user"}

        >

        </List>
    )
}
export default Page
