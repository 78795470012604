import Detail from "../parts/Detail";
import {STATUS as SOCIALSTATUS} from "./SocialList";
import {useNavigate} from "react-router-dom";

const Page = ()=>{
    const navigate = useNavigate()
    return (
        <Detail model={"socials"}
                title={"환급신청내역"}
                info={[
                    {
                        label:"신청계정",
                        get:item=>item.expand.user.email
                    },
                    {
                        label:"연락처",
                        get:item=>item.expand.user.phone
                    },
                    {
                        label:"부서",
                        get:item=>item.dept,
                    },
                    {
                        label:"담당자",
                        get:item=>item.charge,
                    },
                    {
                        label:"환급액",
                        get:item=>Number(item.point).toLocaleString()
                    },
                    {
                        label:"상태",
                        get:item=>item.status
                    }
                ]}
                text={[

                    {
                        label:"메모(관리자용)",
                        get:item=>item["cs_memo"],
                        key:"cs_memo"
                    },

                    {
                        label:"취소사유",
                        get:item=>item["memo"],
                        key:"memo"
                    },
                ]}
                files={[
                    {
                        label:"영수증",
                        get:item=>item.receipt
                    },
                    {
                        label:"기타파일",
                        get:item=>item.attach
                    }
                ]}
                expand={"user"}
                status={Object.keys(SOCIALSTATUS)}

        >

        </Detail>
    )
}
export default Page
