import Detail from "../parts/Detail";
import {STATUS as PAPERSTATUS} from "./PaperList";
import {useNavigate} from "react-router-dom";

const Page = ()=>{
    const navigate = useNavigate()
    return (
        <Detail model={"papers"}
                title={"구매등록내역"}
                info={[
                    {
                        label:"신청계정",
                        get:item=>item.expand.user.email
                    },
                    {
                        label:"구매품목",
                        get:item=>item.item,
                        key:"item"
                    },
                    {
                        label:"부서",
                        get:item=>item.dept,
                    },
                    {
                        label:"구매자",
                        get:item=>item.buyer,
                    },
                    {
                        label:"구매금액",
                        get:item=>item.amount.toLocaleString()
                    },
                    {
                        label:"적립금",
                        get:item=>item.point.toLocaleString()
                    },
                    {
                        label:"구매날짜",
                        get:item=>item.purchased?new Date(item.purchased).toLocaleDateString()+new Date(item.purchased).toLocaleTimeString():""
                    },
                    {
                        label:"상태",
                        get:item=>item.status
                    }
                ]}
                text={[

                    {
                        label:"메모(관리자용)",
                        get:item=>item["cs_memo"],
                        key:"cs_memo"
                    },

                    {
                        label:"반려사유",
                        get:item=>item["memo"],
                        key:"memo"
                    },
                ]}
                expand={"user"}
                status={Object.keys(PAPERSTATUS)}

        >

        </Detail>
    )
}
export default Page
