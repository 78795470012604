import {Link, useNavigate} from "react-router-dom";
import {useContext, useState} from "react";
import ApiContext from "../ApiContext";

const menus = [
    {
        title:"메뉴",
        children:[
            {
                title:"대시보드",
                icon:"bi bi-grid-fill",
                to:"/"
            },
            {
                title:"회원",
                icon:"bi bi-file-earmark-person",
                children:[
                    {
                        title:"조회",
                        to:"/user"
                    },
                    {
                        title:"메일 발송",
                        to:"/mail"
                    },
                ]
            },
            {
                title:"신청",
                icon:"bi bi-file-earmark-medical-fill",
                children:[
                    {
                        title:"적립신청",
                        to:"/paper"
                    },
                    {
                        title:"환급신청",
                        to:"/social"
                    }
                ]
            },
            {
                title:"게시판",
                icon:"bi bi-pen-fill",
                children:[
                    {
                        title:"공지사항",
                        to:"/notice"
                    }
                ]
            },
        ]
    }
]
const Item = ({data,current = null})=>{
    return (
        <>
            <li className={"sidebar-title"}>{data.title}</li>
            {
                data.children.map(child=>{
                    let classList = [
                        "sidebar-item"
                    ]
                    if(current === child.to){
                        classList.push("active")
                    }
                    if(child.children){
                        classList.push("has-sub")
                    }
                    return (
                        <Children key={child.title} child={child} classList={classList} />
                    )
                })
            }
        </>
    )
}
const Children = ({child,classList})=>{
    const [open,setOpen]=useState(true)
    return (
        <li
            className={classList.join(" ")}>
            <Link className='sidebar-link' to={child.to??"#"} onClick={()=>setOpen(!open)}>
                <i className={child.icon}></i>
                <span>{child.title}</span>
            </Link>
            {
                child.children?
                    <ul className={open?"submenu active":"submenu"}>
                        {
                            child.children.map(sub=>{
                                return (
                                    <li key={sub.title} className="submenu-item ">
                                        <Link to={sub.to}>{sub.title}</Link>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    :null
            }
        </li>
    )
}
const Menu = ()=>{
    const pb = useContext(ApiContext)
    const navigate = useNavigate()
    const logout = ()=>{
        pb.authStore.clear()
        navigate("/login")
    }
    return (
        <div className="sidebar-menu">
            <ul className="menu">
                {
                    menus.map(item=>{
                        return (
                            <Item key={item.title} data={item} current={null} />
                        )
                    })
                }
                <li className={"sidebar-title"}>
                    <li className={"sidebar-item"}>
                        <a className='sidebar-link' href="#" onClick={logout}>로그아웃</a>
                    </li>
                </li>
            </ul>
        </div>
    )
}
export default Menu
