import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/main/app.css';
import reportWebVitals from './reportWebVitals';
import Provider from "./router";
import Pocketbase from "pocketbase";
import ApiContext from "./ApiContext";
const pb = new Pocketbase("https://naeum.data-heroes.kr/")
pb.autoCancellation(false)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <ApiContext.Provider value={pb}>
          <Provider />
      </ApiContext.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
