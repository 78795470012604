import List from "../parts/List"
import {useState} from "react";
import {Link} from "react-router-dom";
const Page = ()=>{
    return (
        <List model={"notices"} columns={["제목","작성일"]} handler={item=>{
            return [
                item.title,
                item.created.substring(0,10),
            ]
        }
        }
              keywordMatch={["title"]}

        >
            <Link className={"btn btn-primary"} to={"/notice/create"}>새글쓰기</Link>

        </List>
    )
}
export default Page
