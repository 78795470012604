import Detail from "../parts/Detail";
import {useNavigate} from "react-router-dom";
import {STATUS as PAPERSTATUS} from "./PaperList";
import {STATUS as SOCIALSTATUS} from "./SocialList";

const Page = ()=>{
    const navigate = useNavigate()
    return (
        <Detail model={"users"}
                title={"사용자정보"}
                info={[
            {
                label:"총적립금",
                get:item=>(item.expand["papers(user)"]??[]).filter(item=>item.status==="적립완료").map(item=>Number(item.point)).reduce((a,b)=>a+b,0).toLocaleString()
            },
            {
                label:"환급금",
                get:item=>(item.expand["papers(user)"]??[]).filter(item=>item.status==="입금완료").map(item=>Number(item.point)).reduce((a,b)=>a+b,0).toLocaleString()
            },
            {
                label:"이메일",
                get:item=>item.email
            },
            {
                label:"가입날짜",
                get:item=>new Date(item.created).toLocaleDateString()+new Date(item.created).toLocaleTimeString()
            },
            {
                label:"담당자명",
                get:item=>item.charge,
                key:"charge"
            },
            {
                label:"연락처",
                get:item=>item.phone,
                key:"phone"
            },
            {
                label:"회사명",
                get:item=>item.company,
                key:"company"
            },
            {
                label:"부서",
                get:item=>item.dept,
                key:"dept"
            },
        ]}
                files={[
                    {
                        label:"사업자등록증",
                        get:item=>item.business
                    },
                    {
                        label:"통장사본",
                        get:item=>item.account
                    }
                ]}
                tables={[
                    {
                        name:"적립신청내역",
                        columns:["구매품목","적립액","날짜","상태"],
                        navigate:(id)=>{
                            navigate("/paper/"+id)
                        },
                        expand:(record)=>{

                            if(record.expand["papers(user)"]){
                                return record.expand["papers(user)"]
                            }else{
                                return []
                            }
                        },
                        handler:(record)=>{
                            return [
                                record.item,
                                Number(record.point).toLocaleString(),
                                new Date(record.created).toLocaleDateString()+new Date(record.created).toLocaleTimeString(),
                                [record.status,PAPERSTATUS[record.status]]
                            ]
                        }
                    },
                    {
                        name:"환급신청내역",
                        columns:["환급액","날짜","상태"],
                        navigate:(id)=>{
                            navigate("/social/"+id)
                        },
                        expand:(record)=>{

                            if(record.expand["socials(user)"]){
                                return record.expand["socials(user)"]
                            }else{
                                return []
                            }
                        },
                        handler:(record)=>{
                            return [
                                Number(record.point).toLocaleString(),
                                new Date(record.created).toLocaleDateString()+new Date(record.created).toLocaleTimeString(),
                                [record.status,SOCIALSTATUS[record.status]]
                            ]
                        }
                    }
                ]}
                expand={"papers(user),socials(user)"}

        >
        </Detail>
    )
}
export default Page
