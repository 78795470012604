import Auth from "../layouts/Auth";
import {Link, useNavigate} from "react-router-dom";
import {useContext, useState} from "react";
import ApiContext from "../ApiContext";

const Page = ()=>{
    const navigate = useNavigate()
    const pb = useContext(ApiContext)
    const[id,setId]=useState("")
    const[pw,setPw]=useState("")
    const login = ()=>{
        pb.admins.authWithPassword(id,pw).then(()=>{
            navigate("/")
        }).catch(()=>{
            alert("입력한 정보가 일치하지 않습니다.")
        })

    }
    return (
        <Auth>
            <div id="auth-left">
                <div className="auth-logo">
                    <Link to={"/"}><img style={{height:"4rem"}} src="/naeum_logo.png" alt="Logo" /></Link>
                </div>
                <h1 className="auth-title">관리자 로그인</h1>
                <p className="auth-subtitle mb-5">발급받은 로그인정보를 입력해주세요.</p>

                <div className="form-group position-relative has-icon-left mb-4">
                    <input type="text" className="form-control form-control-xl" placeholder="Username" onChange={e=>setId(e.target.value)} />
                    <div className="form-control-icon">
                        <i className="bi bi-person"></i>
                    </div>
                </div>
                <div className="form-group position-relative has-icon-left mb-4">
                    <input type="password" className="form-control form-control-xl" placeholder="Password" onChange={e=>setPw(e.target.value)} onKeyUp={e=>e.key==="Enter"?login():null}  />
                    <div className="form-control-icon">
                        <i className="bi bi-shield-lock"></i>
                    </div>
                </div>
                <button className="btn btn-primary btn-block btn-lg shadow-lg mt-5" onClick={login}>로그인</button>

            </div>
        </Auth>
    )
}
export default Page;
