import ReactQuill from "react-quill";
import {useContext, useEffect, useState} from "react";
import "react-quill/dist/quill.snow.css"
import ApiContext from "../ApiContext";
import {useParams} from "react-router-dom";
const Page = ()=>{
    const pb = useContext(ApiContext)
    const [loading,setLoading]=useState(false)
    const [form,setForm]=useState({
        mail:"",
        title:"",
        contents:""
    })
    const submit = async ()=>{
        setLoading(true)
        let xhr = new XMLHttpRequest();
        xhr.open("POST", "//flow-api.data-heroes.kr/api/custom-mail");

        xhr.setRequestHeader("Accept", "application/json");
        xhr.setRequestHeader("Content-Type", "application/json");

        xhr.onload = () => {
            alert('전송완료.')
            window.location.reload()
            setLoading(false)
        };
        let data = {
            type:"naeum",
            from:"ny201800@naver.com",
            subject:"[내음공간]"+form.title,
            text:form.contents
        }
        if(form.mail){
            let mail = form.mail.split(",").map(m=>m.trim())
            if(mail.length>1){
                data["multi"]=mail
            }else{
                data["email"]=mail[0]
            }
        }else{
            let email = await getUserList()
            data["multi"]=email
        }

        xhr.send(JSON.stringify(data));
    }

    const updateForm=(key,value)=>{
        let obj = {}
        obj[key]=value
        setForm({
            ...form,
            ...obj
        })
    }
    const getUserList= async ()=>{
        return pb.collection("users").getFullList().then(res=>{
            return res.map(record=>record.email)
        })
    }
    return (
        <div className="card">
            <div className="card-content">
                <div className="card-body">
                    <h4 className="card-title">메일쓰기</h4>
                    <div className="form-group">
                        <label htmlFor="feedback1" className="sr-only">수신</label>
                        <input placeholder={",(쉼표)로 구분합니다. 빈 상태로 전송할 경우 모든 회원에게 전송합니다."} type="text" className="form-control" defaultValue={form.mail} onChange={e=>updateForm("mail",e.target.value)} />
                        <label htmlFor="feedback1" className="sr-only">제목</label>
                        <input type="text" className="form-control" defaultValue={form.title} onChange={e=>updateForm("title",e.target.value)} />
                        <label htmlFor="feedback1" className="sr-only">내용</label>
                        <ReactQuill theme={"snow"} modules={
                            {
                                toolbar:[
                                    [{ 'size': ['small', false, 'large', 'huge'] }],
                                    ['bold', 'italic', 'underline', 'strike'],
                                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                    [{ 'indent': '-1'}, { 'indent': '+1' }],
                                    [{ 'color': [] }, { 'background': [] }],
                                    ['link', 'image']
                                ]
                            }

                        } value={form.contents} onChange={v=>updateForm("contents",v)} />
                        <hr/>
                        <div className="form-actions d-flex justify-content-end">
                            <button type="button" className="btn btn-primary me-1" onClick={submit} disabled={loading}>전송{loading?"중":""}</button>
                        </div>
                        {
                            loading?
                                <p>전송 인원이 많은 경우 시간이 오래 걸립니다.</p>
                                :null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Page
