import {useContext, useEffect, useState,useMemo} from "react";
import ApiContext from "../ApiContext";
import {useNavigate} from "react-router-dom";

const sum = (list, column)=>{
    return list.map(record=>Number(record[column])).reduce((a,b)=>a+b,0)
}
const TYPE = {
    "papers": {
        name:"구매신청",
        uri:"paper"
    },
    "socials":{
        name:"환급신청",
        uri:"social"
    }
}
const Page = ()=>{
    const navigate =useNavigate()
    const pb = useContext(ApiContext)
    const [list,setList]=useState([])
    const [users,setUsers]=useState([])
    const [ready,setReady]=useState([])
    const [notices,setNotices]=useState([])
    const [count,setCount]=useState({
        user:0,
        paper:0,
        social:0
    })
    const [bulk,setBulk]=useState([])
    useEffect(()=>{
       load()
    },[])
    const totalDeposit = useMemo(()=>{
        return sum(list,"deposit")
    },[list])
    const totalWithdraw = useMemo(()=>{
        return sum(list,"withdraw")
    },[list])
    const filteredReadyList = useMemo(()=>{
        let result = [...ready].sort((a,b)=>{
            let r = 0
            if(a.created>b.created){
                r = 1
            }else if(a.created<b.created){
                r = -1
            }
            return r
        }).splice(0,5)
        return result
    },[ready])
    const load = ()=>{
        pb.collection("user_stats").getFullList().then(res=>{
            setList(res)
        })
        pb.collection("notices").getList(1,3,{
            sort:"-created"
        }).then(res=>{
            setNotices(res.items)
        })
        Promise.all([readySocialLoad(),readyPaperLoad(),userLoad()]).then(([rs,rp,userload])=>{
            setCount({
                ...count,
                paper:rp.totalItems,
                social:rs.totalItems,
                user:userload.totalItems
            })
            setReady([
                ...rs.items,
                ...rp.items
            ])
            setUsers(userload.items)
        })
    }
    const readyPaperLoad = ()=>{
        return pb.collection("papers").getList(1,5,{
            sort:"-created",
            filter:`status="신청완료"`,
            expand:"user"
        }).then(res=>{
            return res
        })
    }
    const readySocialLoad = ()=>{
        return pb.collection("socials").getList(1,5,{
            sort:"-created",
            filter:`status="신청완료"`,
            expand:"user"
        }).then(res=>{
            return res
        })
    }

    const userLoad = ()=>{
        return pb.collection("users").getList(1,4,{
            sort:"-created"
        }).then(info=>{
            return info
        })
    }
    const parseDate = (datestring)=>{
        let date = new Date(datestring)
        return date.toLocaleDateString() + date.toLocaleTimeString()
    }
    const bulkToggle =(record)=>{
        let findIndex = bulk.findIndex(v=>v.id===record.id && v.collectionName===record.collectionName)
        if(findIndex!==-1){
            bulk.splice(findIndex,1)
            setBulk(bulk)
        }else{
            setBulk([
                ...bulk,
                record
            ])
        }
    }
    const bulkUpdate = ()=>{
        if(bulk.length){
            Promise.all(bulk.map(record=>{
                return pb.collection(record.collectionName).update(record.id,{
                    status:"담당자확인"
                })
            })).then(()=>{
                alert("완료")
                load()
                setBulk([])
            })
        }else{
            alert("선택된 항목이 없습니다.")
        }
    }
    return (
        <>
            <div className="page-heading">
                <h3>내음공간 상생기반 플랫폼 관리자페이지</h3>
            </div>
            <div className="page-content">
                <section className="row">
                    <div className="col-12 col-lg-9">
                        <div className="row">
                            <div className="col-6 col-lg-3 col-md-6">
                                <div className="card">
                                    <div className="card-body px-4 py-4-5">
                                        <div className="row">
                                            <div
                                                className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start ">
                                                <div className="stats-icon purple mb-2">
                                                    <i className="iconly-boldShow"></i>
                                                </div>
                                            </div>
                                            <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                                                <h6 className="text-muted font-semibold text-center">미정산적립금</h6>
                                                <h6 className="font-extrabold mb-0 text-center">{(totalDeposit - totalWithdraw).toLocaleString()}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-lg-3 col-md-6">
                                <div className="card">
                                    <div className="card-body px-4 py-4-5">
                                        <div className="row">
                                            <div
                                                className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start ">
                                                <div className="stats-icon blue mb-2">
                                                    <i className="iconly-boldProfile"></i>
                                                </div>
                                            </div>
                                            <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                                                <h6 className="text-muted font-semibold text-center">가입자</h6>
                                                <h6 className="font-extrabold mb-0 text-center">{count.user.toLocaleString()}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-lg-3 col-md-6">
                                <div className="card">
                                    <div className="card-body px-4 py-4-5">
                                        <div className="row">
                                            <div
                                                className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start ">
                                                <div className="stats-icon green mb-2">
                                                    <i className="iconly-boldAdd-User"></i>
                                                </div>
                                            </div>
                                            <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                                                <h6 className="text-muted font-semibold text-center">구매신청</h6>
                                                <h6 className="font-extrabold mb-0 text-center">{count.paper.toLocaleString()}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-lg-3 col-md-6">
                                <div className="card">
                                    <div className="card-body px-4 py-4-5">
                                        <div className="row">
                                            <div
                                                className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start ">
                                                <div className="stats-icon red mb-2">
                                                    <i className="iconly-boldBookmark"></i>
                                                </div>
                                            </div>
                                            <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                                                <h6 className="text-muted font-semibold text-center">환급신청</h6>
                                                <h6 className="font-extrabold mb-0 text-center">{count.social.toLocaleString()}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className={"row"}>
                                            <div className={"col-2"}>
                                                <h4>대기중인 신청</h4>
                                            </div>
                                            <div className={"col"} style={{textAlign:"right"}}>
                                                <button className={"btn btn-secondary"} onClick={bulkUpdate}>
                                                    일괄 담당자확인
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-hover table-lg">
                                                <thead>
                                                <tr>
                                                    <th style={{textAlign:"center"}}>타입</th>
                                                    <th style={{textAlign:"center"}}>신청자</th>
                                                    <th style={{textAlign:"center"}}>적립금</th>
                                                    <th style={{textAlign:"center"}}>날짜</th>
                                                    <th style={{textAlign:"center"}}>일괄처리</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    filteredReadyList.map(record=>{
                                                        const onClick = ()=>{
                                                            navigate("/"+TYPE[record.collectionName].uri+"/"+record.id)
                                                        }
                                                        return (
                                                            <tr key={record.id}>
                                                                <td style={{textAlign:"center"}} onClick={onClick}>{TYPE[record.collectionName].name}</td>
                                                                <td style={{textAlign:"center"}} onClick={onClick}>{record.expand.user.company}</td>
                                                                <td style={{textAlign:"right"}} onClick={onClick}>{Number(record.point).toLocaleString()}</td>
                                                                <td style={{textAlign:"center"}} onClick={onClick}>{parseDate(record.created)}</td>
                                                                <td style={{textAlign:"center"}}><input type="checkbox" onChange={()=> {
                                                                    bulkToggle(record)
                                                                }}/></td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-xl-4">
                                <div className="card">
                                    <div className="card-header">
                                        <h4>적립/정산 현황</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-hover table-bordered">
                                                <tr>
                                                    <th>총적립금</th>
                                                    <td>
                                                        <h5 className="mb-0" style={{textAlign:"right"}}>{totalDeposit.toLocaleString()}</h5>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>기적립금</th>
                                                    <td>
                                                        <h5 className="mb-0" style={{textAlign:"right"}}>{totalWithdraw.toLocaleString()}</h5>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>미정산적립금</th>
                                                    <td>
                                                        <h5 className="mb-0" style={{textAlign:"right"}}>{(totalDeposit-totalWithdraw).toLocaleString()}</h5>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-8">
                                <div className="card">
                                    <div className="card-header">
                                        <h4>최근 공지사항</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-hover table-lg">
                                                <thead>
                                                <tr>
                                                    <th>날짜</th>
                                                    <th>제목</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    notices.map(notice=>{
                                                        return (
                                                            <tr key={notice.id} onClick={()=>navigate(`/notice/${notice.id}`)}>
                                                                <td className="col-3" style={{cursor:"pointer"}}>
                                                                    <div className="d-flex align-items-center">
                                                                        <p className="font-bold mb-0">{notice.created.substring(0,10)}</p>
                                                                    </div>
                                                                </td>
                                                                <td className="col-auto" style={{cursor:"pointer"}}>
                                                                    <p className=" mb-0">{notice.title}</p>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3">
                        <div className="card">
                            <div className="card-body py-4 px-4">
                                <div className="d-flex align-items-center">
                                    <div className="avatar avatar-xl">
                                        <img src="/android-chrome-512x512.png" alt="Face 1" />
                                    </div>
                                    <div className="ms-3 name">
                                        <h5 className="font-bold">관리자</h5>
                                        <h6 className="text-muted mb-0">@내음공간</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <h4>최근 가입자</h4>
                            </div>
                            <div className="card-content pb-4">
                                {
                                    users.map(user=>{
                                        return (
                                            <div key={user.id} className="recent-message d-flex px-4 py-3">
                                                <div className="name ms-4">
                                                    <h5 className="mb-1">{user.company}</h5>
                                                    <h6 className="text-muted mb-0">@{user.dept}</h6>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default Page
