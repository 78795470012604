import ReactQuill from "react-quill";
import {useContext, useEffect, useState} from "react";
import "react-quill/dist/quill.snow.css"
import ApiContext from "../ApiContext";
import {useParams} from "react-router-dom";
const Page = ()=>{
    const pb = useContext(ApiContext)
    const params = useParams()
    const [form,setForm]=useState({
        title:"",
        contents:""
    })
    const [comments,setComments]=useState([])
    const [isNew,setIsNew]=useState(true)
    const [newComment,setNewComment]=useState("")
    useEffect(()=>{
        load()
    },[params])
    const submit = ()=>{
        return new Promise((resolve,reject)=>{
            if(!isNew){
                update().then(()=>resolve()).catch(()=>{
                    reject()
                })
            }else{
                save().then(()=>resolve()).catch(()=>{
                    reject()
                })
            }
        }).then(()=>{
            alert("완료")
            window.history.back()
        }).catch(()=>{
            alert("에러가 발생했습니다.")
        })
    }
    const load = ()=>{
        if(params.id){
            pb.collection("notices").getOne(params.id,{
                expand:"comments(notice)",
            }).then(res=>{
                if(res){
                    setIsNew(false)
                    console.log(res.file)
                    setForm({
                        title:res.title,
                        contents:res.contents,
                        attach:res.file?[res.file,pb.getFileUrl(res,res.file)]:null
                    })
                    if(res.expand["comments(notice)"]){

                        setComments(res.expand["comments(notice)"])
                    }
                }
            })
        }
    }
    const updateComment=()=>{
        pb.collection("comments").create({
            content:newComment,
            notice:params.id,
            name:"관리자"
        }).then(()=>{
            load()
            setNewComment("")
        })
    }
    const save = ()=>{

        let multiPartForm = new FormData()
        Object.keys(form).forEach(key=>{
            multiPartForm.append(key,form[key])
        })
        return pb.collection("notices").create(multiPartForm)
    }
    const update = ()=>{

        let multiPartForm = new FormData()
        Object.keys(form).forEach(key=>{
            multiPartForm.append(key,form[key])
        })
        return pb.collection("notices").update(params.id,multiPartForm)
    }
    const destroy = ()=>{
        let conf = window.confirm("게시글을 삭제합니다.")
        if(conf){
            pb.collection("notices").delete(params.id).then(()=>{
                window.history.back()
            })
        }
    }


    const updateForm=(key,value)=>{
        let obj = {}
        obj[key]=value
        setForm({
            ...form,
            ...obj
        })
    }
    return (
        <div className="card">
            <div className="card-content">
                <div className="card-body">
                    <h4 className="card-title">공지사항</h4>
                    <div className="form-group">
                        <label htmlFor="feedback1" className="sr-only">제목</label>
                        <input type="text" className="form-control" defaultValue={form.title} onChange={e=>updateForm("title",e.target.value)} />
                        <label htmlFor="feedback1" className="sr-only">내용</label>
                        <ReactQuill theme={"snow"} modules={
                            {
                                toolbar:[
                                    [{ 'header': [1,2,3,4,5,false] }],
                                    ['bold', 'italic', 'underline', 'strike'],
                                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                    [{ 'indent': '-1'}, { 'indent': '+1' }],
                                    [{ 'size': ['small', false, 'large', 'huge'] }],
                                    [{ 'color': [] }, { 'background': [] }],
                                    [{ 'align': [] }],
                                    ['link', 'image']
                                ]
                            }

                        } value={form.contents} onChange={v=>updateForm("contents",v)} />
                        <label htmlFor="feedback1" className="sr-only">파일</label>
                        <input className={"form-control"} type="file" onChange={e=> {
                            if(e.target.files.length){
                                let newFile = e.target.files[0]
                                if(newFile.size>5242880){
                                    alert("최대용량(5Mb)를 초과합니다.")
                                    e.target.value=""
                                    return
                                }
                                updateForm("file", e.target.files[0])
                            }
                        }}/>
                        {
                            form.attach && !form.file?
                                <>
                                    <a className={"btn btn-primary mt-2"} target={"_blank"} href={form.attach[1]} >{form.attach[0]}</a>
                                </>
                                :null
                        }
                        <hr/>
                        {
                            comments.length?
                                <>
                                    <table className="table table-hover table-lg">
                                        <thead>
                                        <tr>
                                            <th>날짜</th>
                                            <th>유저</th>
                                            <th>댓글</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {

                                            comments.map(comment=>{
                                                const user = comment.expand.user
                                                return (
                                                    <tr key={comment.id} >
                                                        <td className="col-2">
                                                            <div className="d-flex align-items-center">
                                                                <p className="font-bold mb-0">{comment.created.substring(0,10)}</p>
                                                            </div>
                                                        </td>
                                                        <td className="col-2">
                                                            <div className="d-flex align-items-center">
                                                                <p className="font-bold mb-0">{comment.name}</p>
                                                            </div>
                                                        </td>
                                                        <td className="col-auto">
                                                            <p className=" mb-0">{comment.content}</p>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                    <div className={"row"}>
                                        <div className={"col"}>
                                            <input className={"form-control"} type="text" value={newComment} onChange={e=>setNewComment(e.target.value)}/>
                                        </div>
                                        <div className={"col-1"}>
                                            <button className={"btn btn-secondary"} onClick={updateComment}>댓글달기</button>
                                        </div>
                                    </div>
                                    <hr/>
                                </>
                                :null
                        }

                        <div className="form-actions d-flex justify-content-end">
                            <button type="button" className="btn btn-primary me-1" onClick={submit}>저장</button>
                            <button type="button" className="btn btn-danger me-1" onClick={destroy}>삭제</button>
                            <button type="button" className="btn btn-light-primary" onClick={()=>window.history.back()}>뒤로가기</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Page
