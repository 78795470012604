import List from "../parts/List"
import {useState} from "react";
const Page = ()=>{
    return (
        <List model={"users"} columns={["이메일","회사","부서"]} handler={item=>{
            return [
                item.email,
                item.company,
                item.dept
            ]
        }
        }
              keywordMatch={["email","company","dept"]}

        >

        </List>
    )
}
export default Page
